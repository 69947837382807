import CountryPreview from '../CountryPreview';
import CountryExtendedInfo from '../CountryDetails';

export function CountyFullView({ code }: { code: string }) {
  return (
    <>
      <CountryPreview code={code} />
      <CountryExtendedInfo code={code} />
    </>
  );
}

export default CountyFullView;
