import { useQuery } from '@apollo/client';
import { graphql } from '../../gql';

export const GET_COUNTRY = graphql(/* GraphQL */ `
  query Country($code: ID!) {
    country(code: $code) {
      name
      emoji
      code
      capital
      continent {
        code
        name
      }
      currency
      emojiU
      languages {
        code
        name
        native
        rtl
      }
      native
      phone
      states {
        code
        name
      }
    }
  }
`);

export function CountryExtendedInfo({ code }: { code: string }) {
  const { data, loading, error } = useQuery(GET_COUNTRY, {
    variables: { code },
    skip: !code,
  });

  const country = data?.country;

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  if (!country) return <p></p>;

  return (
    <div className="container mx-auto">
      <table className="table-auto">
        <tbody>
          <tr>
            <td className="py-2">Capital</td>
            <td className="px-4 py-2">{country?.capital}</td>
          </tr>
          <tr>
            <td className="py-2">Continent</td>
            <td className="px-4 py-2">{country?.continent.name}</td>
          </tr>
          <tr>
            <td className="py-2">Currency</td>
            <td className="px-4 py-2">{country?.currency}</td>
          </tr>
          <tr>
            <td className="py-2">Emoji</td>
            <td className="px-4 py-2">{country?.emoji}</td>
          </tr>
          <tr>
            <td className="py-2">EmojiU</td>
            <td className="px-4 py-2">{country?.emojiU}</td>
          </tr>
          <tr>
            <td className="py-2">Languages</td>
            <td className="px-4 py-2">
              {country?.languages.map((language) => (
                <div key={language.code}>
                  {language.name} ({language.native})
                </div>
              ))}
            </td>
          </tr>
          <tr>
            <td className="py-2">Native</td>
            <td className="px-4 py-2">{country?.native}</td>
          </tr>
          <tr>
            <td className="py-2">Phone</td>
            <td className="px-4 py-2">{country?.phone}</td>
          </tr>
          <tr>
            <td className="py-2">States</td>
            <td className="px-4 py-2">
              {country?.states.map((state) => (
                <div key={state.code}>{state.name}</div>
              ))}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default CountryExtendedInfo;
