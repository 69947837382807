import { ApolloProvider, useQuery } from '@apollo/client';
import { graphql } from '../../gql';
import { client } from '@castera/data-access-countries-api';
import { useEffect } from 'react';

export const GET_COUNTRY_PREVIEW = graphql(/* GraphQL */ `
  query CountryPreview($code: ID!) {
    country(code: $code) {
      name
      emoji
      code
    }
  }
`);

export function CountryPreview({ code }: { code: string }) {
  console.log({ code });

  const { data, loading, error } = useQuery(GET_COUNTRY_PREVIEW, {
    variables: { code },
    skip: !code,
  });

  const countryPreview = data?.country;

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  if (!countryPreview)
    return (
      <div className="container mx-auto">
        <h1 className="text-4xl font-bold">Country not found</h1>
      </div>
    );

  return (
    <div className="container mx-auto">
      <h1 className="text-4xl font-bold">
        {countryPreview.emoji} {countryPreview.name}
      </h1>
    </div>
  );
}

export default CountryPreview;
