import { graphql } from '../../gql';
import { useQuery } from '@apollo/client';
import { useState } from 'react';

export const GET_COUNTRIES = graphql(/* GraphQL */ `
  query Countries {
    countries {
      code
      name
      emoji
    }
  }
`);

export function CountriesList({ Link }: { Link: React.ComponentType<any> }) {
  const { data, loading, error } = useQuery(GET_COUNTRIES);

  const [search, setSearch] = useState('');
  const filteredCountries = data?.countries.filter((country) => {
    return country.name.toLowerCase().includes(search.toLowerCase());
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="flex flex-col gap-2">
      <h1>Test test test</h1>
      <div className="flex items-center justify-between">
        <div className="mt-2 flex flex-1 items-center">
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <div className="relative flex-1">
            <input
              id="search"
              name="search"
              className="block w-full rounded-md border border-gray-300 bg-white py-2 pl-2 pr-3 leading-5 placeholder-gray-500 focus:border-indigo-500 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
              placeholder="Search"
              type="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="mt-4">
        <h2 className="text-sm font-medium text-gray-500">
          {filteredCountries?.length} results
        </h2>
      </div>

      <ul className="divide-y divide-gray-200">
        {filteredCountries?.map((country) => (
          <Link
            href={`/country/${country.code}`}
            key={country.code}
            className="block transition duration-150 ease-in-out hover:bg-gray-50 focus:bg-gray-50 focus:outline-none"
          >
            <li key={country.code} className="py-4">
              <div className="flex items-center space-x-4">
                <div className="flex-shrink-0">
                  <span className="inline-block flex h-6 w-6 justify-center overflow-hidden rounded-full bg-gray-100">
                    <span className="sr-only">{country.name}</span>
                    <span aria-hidden="true">{country.emoji}</span>
                  </span>
                </div>
                <div className="min-w-0 flex-1">
                  <p className="truncate text-sm font-medium text-gray-900">
                    {country.name}
                  </p>
                </div>
              </div>
            </li>
          </Link>
        ))}
      </ul>
    </div>
  );
}
